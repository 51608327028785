.body-login {
  background-color: var(--gray);
  background-image: none;
}

#wrapper-login {
  margin: 0 auto;
  min-height: 100vh;
  overflow: hidden;
}

#contiene-login {
  min-width: 268px;
  max-width: 320px;
  min-height: 500px !important;
  margin: 0 auto;
}

#spazia-login {
  height: 10vh; /*18vh con risoluzioni maggiori*/
}

.login-box-fix {
  min-height: 400px !important;
  padding: 30px !important;
  background-color: var(--grayMed) !important;
}

.title-regulation {
  color: var(--white);
  font-size: var(--font-size-xxl);
  font-weight: bold;
  text-align: center;
}

.title-fix {
  min-height: 60px;
  margin: 0 auto;
}

.title-fixEura {
  background-color: var(--white) !important;
}

.title-fixYork {
  background-color: var(--azienda) !important;
}

.subtitle-fix {
  margin-top: 13px !important;
  min-height: 70px;
  margin: auto;
}

.cell-subtitle {
  width: 100%;
  height: 52px;
  margin-top: 2%;
  color: var(--white);
  border-radius: 3px;
  background: var(--azienda) url(../../assets/images/tratteggio.png) top left
    repeat;
}

.cell-subtitle-eura {
  padding-top: 7px;
}

.cell-subtitle-york {
  padding-top: 2px;
}

.login-button-box {
  min-width: 246px;
  min-height: 46px;
  background-color: var(--black);
  margin-top: 20px;
  border-style: solid;
  border-radius: 3px;
  color: var(--white);
  font-size: var(--font-size-xl);
  font-weight: bold;
}

.login-button-box:hover {
  background-color: var(--azienda);
}

.image-fix {
  margin: 13px;
}

.image-sw-fix {
  margin-top: 6px;
}

#spazio-in-mezzo-al-login {
  min-height: 36px;
}

#coo-cell {
  background-color: var(--yellowLight);
  color: var(--black);
  border-radius: 3px;
  position: fixed;
  width: 100%;
  height: 60px;
  bottom: 0px;
  margin: 0 auto;
  padding: 20px 0px 0px 0px;
  font-size: var(--font-size-s);
}

.privacy {
  color: var(--azienda);
  cursor: pointer;
}

.privacy:hover {
  color: var(--grayDark);
}

.notVisible {
  visibility: hidden;
}
.zeroPad {
  padding: 0 !important;
  display: inline-block;
}

.align-on-top {
  vertical-align: top;
}

#titleFred {
  background-color: var(--blue);
  color: var(--white);
  border-color: var(--grayDark);
}
#titleCald {
  background-color: var(--red);
  color: var(--white);
  border-color: var(--grayDark);
}

#rcCald {
  height: auto;
  margin-top: 6px;
}

#tableAirFred td:first-child,
#tableWattC td:first-child,
#tableAirCald td:first-child,
#tableWattH td:first-child {
  text-align: right;
  width: 180px;
}

.caption-on-top {
  caption-side: top;
}

.table-caption {
  caption-side: top;
}
.sidebar-main {
  max-width: 200px;
  max-height: 400px;
  position: relative;
  text-transform: uppercase;
}

.sidebar-main .checkbox {
  margin: 0px !important;
}

.space-for-logo {
  min-height: 140px;
  padding-bottom: 20px;
}
.logo-div {
  position: absolute;
  text-align: center;
  min-height: 100px;
  max-width: 180px;
  background-color: var(--white) !important;
  margin-top: 10px;
  margin-left: 10px !important;
}

.logo-divGeni {
  padding-top: 15% !important;
  padding-bottom: 10% !important;
}
.logo-divLase {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
#vers {
  color: var(--white);
  font-weight: bold;
  text-transform: uppercase;
}

.lateralMenu-division {
  min-height: 50px;
}

#logo-divAz {
  position: fixed;
  bottom: 10px;
  padding-bottom: 0px !important;
}
.logo-divEura {
  background-color: var(--azienda) !important;
  padding-top: 36px !important;
}
.logo-divYork {
  background-color: var(--azienda) !important;
  padding-top: 28px !important;
}
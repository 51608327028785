div {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: var(--font-size-m);
  vertical-align: baseline;
  background: transparent;
}

.container-fluid {
  margin: auto;
  min-width: 1149px;
}

h4 {
  font-size: var(--font-size-xl);
}

.btn {
  width: 90px;
  height: 42px;
  overflow: hidden;
}

caption {
  padding: 2px 0;
}

select {
  width: 190px;
  margin: 0px;
  padding-left: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, var(--grayMed) 50%),
    linear-gradient(135deg, var(--grayMed) 50%, transparent 50%),
    linear-gradient(to right, var(--white), var(--white));
  background-position: calc(100% - 8px) calc(0.5em + 1px),
    calc(100% - 3px) calc(0.5em + 1px), calc(100% - 1.3em) 0em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-radius: 3px;
}

input:focus, select:focus {
  border: 1px solid var(--greenShiny);
  box-shadow: none;
}

.table > tbody > tr > td {
  padding: 2px;
  border-top: 0.1em solid var(--white);
  vertical-align: middle;
}

.cell {
  background-color: var(--white);
  border: 1px solid var(--grayDark);
  border-radius: 3px;
  padding: 2px;
}
.cell [class^="col"] {
  padding: 2px;
}
.cell .btn {
  border-radius: 3px;
}
.cell-title {
  position: relative;
  width: 100%;
  height: auto;
  padding: 4px 0px;
  background-color: var(--grayMed);
  color: var(--white);
  border: 1px solid var(--grayDark);
  border-radius: 3px;
  text-align: center;
  text-transform: uppercase;
}
.cell-title h4 {
  margin: 2px 0;
}

.tooltip-react {
  max-width: 300px;
  background-color: var(--greenLight) !important;
  opacity: 0;
  color: white !important;
  font-size: var(--font-size-s);
  border-radius: 10px;
  z-index: 30000;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

._loading_overlay_content {
  position: fixed;
  top: 400px;
  left: 47vw;
}

.disabled_mc {
  opacity: 0.5;
  pointer-events: none;
}
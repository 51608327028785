.allerta {
  position: relative;
  width: 600px;
  height: 55px;
  text-align: center;
  margin: 0 auto;
  padding-top: 14px;
  visibility: hidden;
  font-size: var(--font-size-xl);
  background-color: var(--yellow);
  color: var(--grayDark);
  border: 1px solid var(--grayDark);
  border-radius: 3px;
  z-index: 2000;
}

.duerighe {
  padding-top: 4px;
}

.popup {
  visibility: visible;
  opacity: 0.8;
}
.esp-text {
  display: inline-block !important;
  text-align: left !important;
}

#rcOpCond {
  height: auto;
  min-width: 212px;
  max-width: 230px;
  padding: 4px;
}

#rcOpCond .cell-title {
  margin-bottom: 6px;
}

#formRisc {
  margin-bottom: 2px;
}

#euroCond {
  min-height: 52px;
}
.body-ris {
  background-color: var(--greenLight);
  background-image: none;
}

#wrapper-ris {
  margin: 0 -12px;
  max-height: 100vh;
  overflow-y: hidden;
}

#results {
  margin-left: -14px;
  margin-right: -14px;
}

.ReactTable {
  text-align: center;
  cursor: pointer;
}

.ReactTable .rt-thead.-header {
  background-color: var(--grayMed);
  color: var(--white);
}

.ReactTable .rt-thead {
  overflow-y: scroll;
  overflow-x: hidden;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 1px 1px var(--greenShiny);
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 1px 1px var(--greenShiny);
}

.ReactTable .rt-resizer {
  width: 8px;
  right: -4px;
}

.th-graL {
  background-color: var(--gray);
  color: var(--white);
}

.th-blu {
  background-color: var(--blue);
  color: var(--white);
}

.th-red {
  background-color: var(--red);
  color: var(--white);
}

.td-graL {
  background-color: var(--grayLighter);
}

.td-blu {
  background-color: var(--blueLighter);
}

.td-red {
  background-color: var(--redLighter);
}

.selectedRow {
  background-color: var(--green) !important;
  color: var(--white) !important;
}

.allarme {
  color: var(--red);
  opacity: 0.5;
}

.tooltip-react-minLw {
  max-width: 600px;
  background-color: var(--greenLight) !important;
  opacity: 0;
  color: white !important;
  font-size: var(--font-size-s);
  border-radius: 10px;
  z-index: 30000;
}

.tooltip-react-alarm {
  max-width: 200px;
  background-color: var(--greenLight) !important;
  opacity: 0;
  color: white !important;
  font-size: var(--font-size-s);
  border-radius: 10px;
  z-index: 40000;
}
/*color*/
.checkbox {
  background-color: var(--white);
  color: var(--grayDark);
  border: 1px solid var(--grayDark);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  min-width: 30px;
  max-height: 20px;
  margin: 0px 4px;
  padding: 0px;
}
.checkbox:focus {
  background-color: var(--white);
  color: var(--grayDark);
  border: 1px solid var(--greenShiny);
  box-shadow: none;
}
.checkbox:hover {
  background-color: var(--grayLight);
  color: var(--white);
  border-color: var(--grayDark);
  box-shadow: none;
}
.isChecked {
  background-color: var(--gray);
  color: var(--white);
  border-color: var(--grayDark);
  box-shadow: none;
}
.isChecked:focus {
  background-color: var(--gray);
  color: var(--white);
  border: 1px solid var(--greenShiny);
  box-shadow: none;
}

.isBlue:hover {
  background-color: var(--blue);
  color: var(--white);
}
.isBlue:focus {
  box-shadow: none;
}
.isBlueChecked {
  background-color: var(--blue);
  color: var(--white);
  box-shadow: none;
}
.isBlueChecked:hover {
  background-color: var(--blueLight);
}
.isBlueChecked:focus {
  background-color: var(--blue);
  color: var(--white);
  border: 1px solid var(--greenShiny);
  box-shadow: none;
}

.isRed:hover {
  background-color: var(--red);
  color: var(--white);
}
.isRed:focus {
  box-shadow: none;
}
.isRedChecked {
  background-color: var(--red);
  color: var(--white);
  box-shadow: none;
}
.isRedChecked:hover {
  background-color: var(--redLight);
}
.isRedChecked:focus {
  background-color: var(--red);
  color: var(--white);
  border: 1px solid var(--greenShiny);
  box-shadow: none;
}

.isGreen:hover {
  background-color: var(--green);
  color: var(--white);
}
.isGreen:focus {
  box-shadow: none;
}
.isGreenChecked {
  background-color: var(--green);
  color: var(--white);
  box-shadow: none;
}
.isGreenChecked:hover {
  background-color: var(--greenLight);
}
.isGreenChecked:focus {
  background-color: var(--green);
  color: var(--white);
  border: 1px solid var(--greenShiny);
  box-shadow: none;
}

.isDarkGray {
  background-color: var(--white);
  color: var(--black);
  box-shadow: none;
}
.isDarkGray:hover {
  background-color: var(--green);
  color: var(--white) !important;
}
.isDarkGray:focus {
  box-shadow: none;
}
.isDarkGrayChecked {
  background-color: var(--grayMed);
  color: var(--white);
  box-shadow: none;
}
.isDarkGrayChecked:hover {
  background-color: var(--green);
}
.isDarkGrayChecked:focus {
  background-color: var(--grayMed);
  color: var(--white);
  border: 1px solid var(--greenShiny);
  box-shadow: none;
}
#languages {
  padding-left: 50px;
  max-width: 162px;
  min-height: 26px;
  border-color: var(--grayDark);
  background-color: var(--white);
  font-size: var(--font-size-m);
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
  background-image: linear-gradient(45deg, transparent 50%, var(--grayDark) 50%),
    linear-gradient(135deg, var(--grayDark) 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 20px) calc(1em - 1px),
    calc(100% - 15px) calc(1em - 1px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

#languages:hover {
  background-color: var(--gray);
  color: var(--white);
}

#languages:disabled {
  opacity: 0.5;
  pointer-events: none;
  color: var(--grayDark);
}
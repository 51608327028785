.login-input-box {
  text-align: left !important;
  min-width: 256px;
  min-height: 36px;
  padding-top: 3px;
  padding-bottom: 7px;
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 3px;
  border-style: solid;
  border: none;
}

input[type="text"] {
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  text-align: center;
  margin-right: 2px;;
}

input[type="text"] {
  width: 40px;
}

input[type="radio"],
input[type="checkbox"] {
  margin-right: 4px;
}

input:disabled {
  opacity: 0.5;
  pointer-events: none;
  color: var(--grayDark);
}

#rcOpRes {
  height: auto;
  min-width: 212px;
  max-width: 230px;
  padding: 4px;
}
#rcOpRes .cell-title {
  margin-bottom: 6px;
}

#dMic {
  margin-bottom: 2px;
}

.lax {
  padding: 0 4px;
}

#formLung,
#formLarg,
#formAlte {
  display: inline;
}
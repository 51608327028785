.body-main {
  background-color: var(--grayLight);
  background-image: none;
}

#wrapper-main {
  margin: 0 auto;
  max-height: 100vh;
  overflow: hidden;
}

.main {
  width: 100%;
  min-height: 100vh;
  background-position: center center;
  background-size: cover;
}

#home.main {
  padding-left: 200px;
}

.main .table td,
.main .table th {
  width: auto;
}

.main label {
  font-weight: 100;
}

#spazia-main {
  height: 2vh;
}

#home.main .container-fluid {
  min-width: 1370px;
  max-width: 1370px;
  max-height: 100vh;
}

.row1 > [class^="col"],
.row2 > [class^="col"] {
  padding: 0 8px;
}

.row1 {
  height: 218px;
}

.row1 > div {
  height: inherit;
}

.row2 {
  height: auto;
  margin-top: 2px;
}

.row3 {
  margin-top: 20px;
}

.noMarg {
  margin: 0px;
  padding: 0px;
}

.blink {
  animation: blink 0.25s;
  animation-iteration-count: 4;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
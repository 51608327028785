#boxSerieVel .cell {
  height: calc(100% - 14px);
}
#boxSerieVel .btn-primary {
  min-width: auto;
}
#boxSerieVel .btn-primary:hover, .tickbox:hover {
  background-color: var(--azienda);
  color: var(--white);
}

.center-btn-vertically {
  padding-top: 22px;
  padding-bottom: 30px;
}

.extra-margin-button {
  margin-left: 2px;
  margin-right: 2px;
}

.extra-margin-btnEstHz {
  margin-left: 8px;
  margin-right: 8px;
}

#boxEstHz,
#boxStdDc {
  width: 100%;
  height: calc(50% - 16px);
}
#boxEstHz {
  margin-top: 16px;
}

.btnEst {
  margin-bottom: 22px;
}
#boxHz {
  display: inline-block;
  max-width: 60px;
  max-height: 50px;
}

.lineHeight {
  line-height: 18px;
}

div#boxImg {
  text-align: center;
  height: calc(100% - 16px);
  padding-top: 10px;
  width: 212px;
}
.select-vel {
  width: 5em;
}

.rigaFrom, .rigaTo, .rigaStep {
  height: 26px;
}


.td-center-fix {
  display: block;
  width: 350%;
}

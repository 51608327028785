.checkboxcolumn {
  background-color: var(--white);
  color: var(--grayDark);
  border: 1px solid var(--grayDark);
}

.doublecolumns {
  column-count: 2;
  column-gap: 0px;
}

.tickbox {
  font-size: var(--font-size-xl);
  line-height: 0.7em;
  padding: 0;
  margin: 0;
  display: inline-block;
  position: relative;
  border-collapse: collapse;
  border-spacing: 0;
}

.tickbox-deselect {
  top: -6px;
  padding: 0px !important;
}

.button-all-myfix {
  position: absolute;
  display: block;
  padding: 0px !important;
  margin: 0px !important;
  top: 0px;
  right: 3%;
  max-width: 20px;
  max-height: 15px;
  cursor: pointer;
}
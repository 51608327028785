:root {
  /*COLORS*/
  --blue: rgb(25, 0, 225);
  --blueLight: rgba(25, 0, 225, 0.5);
  --blueLighter: rgba(25, 0, 225, 0.05);
  --red: rgb(225, 0, 25);
  --redLight: rgba(225, 0, 25, 0.5);
  --redLighter: rgba(225, 0, 25, 0.05);
  --green: rgb(0, 144, 54);
  --greenShiny: rgb(0, 255, 98);
  --greenLight: rgb(98, 185, 119);
  --yellow: rgb(255, 224, 51);
  --yellowLight: rgb(251, 228, 97);
  --white: rgb(255, 250, 250);
  --gray: rgb(130, 130, 130);
  --grayLight: rgb(160, 160, 160);
  --grayLighter: rgba(160, 160, 160, 0.05);
  --grayMed: rgb(80, 80, 80);
  --grayDark: rgb(40, 40, 40);
  --black: rgb(0, 0, 0);

  /*FONTS*/
  --font-size-xxl: 140%;
  --font-size-xl: 120%;
  --font-size-m: 100%;
  --font-size-s: 90%;
  --font-size-xs: 80%;
  --font-weight: 100;
}

.btn_width_xxs {
  max-width: 56px;
}
.btn_width_xs {
  width: 104px !important;
}
.btn_width_s {
  min-width: 120px !important;
}
.btn_width_m {
  width: 136px !important;
}
.btn_width_l {
  width: 150px !important;
}
.btn_width_xl {
  min-width: 162px;
}
.btn_width_xxl {
  min-width: 170px;
}
.btn_height_s {
  min-height: 26px;
}
.btn_height_m {
  min-height: 30px;
}
.btn_height_l {
  min-height: 40px;
}

.font_xl {
  font-size: var(--font-size-xl);
  font-weight: bold;
}
.font_l {
  font-size: var(--font-size-xl);
}
.font_m {
  font-size: var(--font-size-m);
}
.font_s {
  font-size: var(--font-size-s);
}
.font_xs {
  font-size: var(--font-size-xs);
}

.main_width_m {
  width: 100% !important;
}
.main_width_l {
  width: 122% !important;
}
.main_width_xl {
  width: 160% !important;
}
